import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import values from "../values";
import { getAllImages } from "../services/firebaseService";
import { Link } from "react-router-dom";

function Gallery() {

    const [images, setImages] = useState([])
    const [paginatedImages, setPages] = useState([]);
    const [page, setPage] = useState(0);

    function paginateImages(images, pageSize = 9) {
        const paginatedImages = [];
        for (let i = 0; i < images.length; i += pageSize) {
            paginatedImages.push(images.slice(i, i + pageSize));
        }
        return paginatedImages;
    }

    useEffect(() => {
        async function getElements() {
            let i = await getAllImages(values.id);
            let p = paginateImages(i, 9)
            setPages(p);
            setImages(p[0])
        }

        getElements();
    }, []);

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollTop + clientHeight === scrollHeight) {
            loadMoreImages();
        }
    };

    const loadMoreImages = () => {
        if (page + 1 < paginatedImages.length) {
            setImages([...images, ...paginatedImages[page + 1]])
            let newPage = page + 1
            setPage(newPage)
        }
    };


    return (
        <>
            <Header />
            <div className="home-body content">
                <div className="mt-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 mb-7">
                        Gallery
                    </h1>
                    {images == undefined || images.length == 0 ? <h5 style={{ "fontWeight": "300" }} class="mb-0">
                        No Images
                    </h5> :
                    <ul onScroll={handleScroll} role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
                        {images.map((image) => (
                            <Link to={"/service/" + image.service} className="linkNoStyle">
                                <li key={image.src} className="relative">
                                    <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-white focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 text-center border">
                                        <img src={image.src} alt="" className="pointer-events-none object-cover group-hover:opacity-75 inline-block" />
                                        <button type="button" className="absolute inset-0 focus:outline-none">
                                            <span className="sr-only">View details for {image.service}</span>
                                        </button>
                                    </div>
                                </li>
                            </Link>
                        ))}
                    </ul>
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Gallery