import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getServices } from "../services/firebaseService";
import Card from 'react-bootstrap/Card';
import { Col, Badge, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import values from "../values";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { HomeCard } from "../components/HomeCard";


function Home() {

    const [services, setServices] = useState([]);
    const [afterThis, setAfterThis] = useState(null);
    const [beforeThis, setBeforeThis] = useState(null);
    const [firstDoc, setFirstDoc] = useState(null);
    const [page, setPage] = useState(0)

    const setDocs = (services, page) => {
        const first_index = 0;
        const last_index = services.length - 1;

        if (last_index >= 0) {
            setAfterThis(services[last_index].doc)
        }
        if (page == 0) {
            setBeforeThis(services[first_index].doc)
        } else {
            setBeforeThis(firstDoc)
        }
        setFirstDoc(services[first_index].doc)
    }

    useEffect(() => {
        async function getElements() {
            let services = await getServices(values.id, afterThis);
            setServices(services);
            setDocs(services, 0)
        }

        getElements();
    }, []);

    async function nextPage() {
        if (services.length == 10) {
            let new_services = await getServices(values.id, afterThis, null);
            if (new_services.length > 0) {
                const newPage = page + 1;
                setPage(newPage)
                setDocs(new_services, newPage);
                setServices(new_services);
            }
        }
    }

    async function prevPage() {
        if (page > 0) {
            let new_services = await getServices(values.id, beforeThis, true);
            if (new_services.length > 0) {
                const newPage = page - 1;
                setPage(newPage)
                setDocs(new_services, newPage);
                setServices(new_services);
            }
        }
    }

    return (
        <>
            <Header />
            <div className="home-body content">
                <div className="mt-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h2 className="text-sm font-medium text-gray-500">Our Offerings</h2>
                    {services.length == 0 ? <h5 style={{ "fontWeight": "300" }} class="mb-0">
                        No Services
                    </h5> :
                        <>
                            <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
                                {services.map((service, index) => (
                                    <HomeCard service={service} key={index} />
                                ))}
                            </ul>
                            {!(page == 0 && services.length < 15) &&
                                <nav className="mt-10 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                                    {page != 0 &&
                                        <div className="-mt-px flex w-0 flex-1">
                                            <a
                                                onClick={prevPage}
                                                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                            >
                                                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Previous
                                            </a>
                                        </div>
                                    }
                                    <div className="-mt-px flex w-0 flex-1 justify-end">
                                        <a
                                            onClick={nextPage}
                                            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                        >
                                            Next
                                            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </a>
                                    </div>
                                </nav>
                            }
                        </>
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home