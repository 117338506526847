import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Success from './pages/Success';
import Contact from './pages/Contact';
import Quiz from './pages/Quiz';
import Service from './pages/Service';
import Gallery from './pages/Gallery';
import values from './values';
import Delivery from './pages/Delivery';
import SuccessQuote from './pages/SuccessQuote';
import { incrementSession } from './services/dbService'
import Quote from './pages/Quote';

function App() {
  let icons = document.querySelectorAll('[data-icon]');
  values.icon = icons[0].dataset.icon;
  let names = document.querySelectorAll('[data-name]');
  values.name = names[0].dataset.name;
  let slogans = document.querySelectorAll('[data-slogan]');
  values.slogan = slogans[0].dataset.slogan;
  let colorss = document.querySelectorAll('[data-colors]');
  values.colors = colorss[0].dataset.colors;
  let ids = document.querySelectorAll('[data-id]');
  values.id = ids[0].dataset.id;
  console.log('here')

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="delivery/:id" element={<Delivery />} />
        <Route path="success" element={<Success />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="contact" element={<Contact />} />
        <Route path="quiz/:id/:pkg" element={<Quiz />} />
        <Route path="service/:id" element={<Service />} />
        <Route path="success-quote" element={<SuccessQuote />} />
        <Route path="quote/:id" element={<Quote />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
