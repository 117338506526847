import { addDoc, collection } from "firebase/firestore";
import values from "../values";
import { db } from "../firebaseConfig";

export async function getCheckoutUrl(name, pkg, responseID, quote) {
    const origin = window.location.protocol + '//' + window.location.host;
    const price = Math.round(pkg.price * 100)
    const fee = Math.round((pkg.price * 0.029 + 0.3) * 100)
    const item = {
        "price_data": {
            "currency": "usd",
            "product_data": {
                "name": name + " - " + (quote == true ? "Quote" : pkg.name)
            },
            "unit_amount": price
        },
        "quantity": 1
    }
    const site = values.id;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "site": site,
        "item": item,
        "fee": fee,
        "origin": origin,
        "clientId": responseID
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

    fetch(values.connect + "/create-checkout-session", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        // window.location.href = result.url
      })
      .catch(error => console.log('error', error)); // TODO
}

export async function uploadResponse(id, responses, pkg) {
    let res = await addDoc(collection(db, "sites", values.id, "responses"), {
        id: id,
        responses: responses,
        package: pkg
    })
    return values.id + "~" + res.id
}