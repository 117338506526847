import values from "../values";

export async function incrementSession(site) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "site": site,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(values.connect + "/increment-session", requestOptions)
        .then(response => response.json())
        .then(result => { })
        .catch(error => console.log('error', error));
}

export async function incrementServiceSession(site, service) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "site": site,
        "service": service
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(values.connect + "/increment-service-session", requestOptions)
        .then(response => response.json())
        .then(result => { })
        .catch(error => console.log('error', error));
}