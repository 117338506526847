import { Link } from "react-router-dom"


function SuccessQuote() {

    return (
        <main className="bg-white px-4 pb-24 pt-16 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
            <div className="mx-auto max-w-3xl">
                <div className="max-w-md">
                    <h1 className="text-base font-medium text-blue-600">Thank you!</h1>
                    <p className="mt-2 text-4xl font-bold tracking-tight">Stay tuned for a quote!</p>
                    <p className="mt-2 text-base text-gray-500">Your quote is in progress. We'll promptly send you an email containing a quote and a purchase link. Keep an eye out!</p>
                </div>
                <div className="max-w-md mt-1">
                    <Link to={"/"}><button className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Return to site</button></Link>
                </div>
            </div>
        </main>
    )
}

export default SuccessQuote