import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import values from "../values";
import { Link, useParams } from "react-router-dom";
import { getFullQuote, getQuote, getService, timeToStr } from "../services/firebaseService";
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { getCheckoutUrl, uploadResponse } from "../services/stripeService";
import gif from "../loading.gif"

function Quote() {

    const { id } = useParams();

    const [quote, setQuote] = useState(null)
    const [q, setQ] = useState(null)
    const [questions, setQuestions] = useState([]);
    const [pdf, setPDF] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function fetchQuote() {
            let quote = await getQuote(values.id, id);
            let q = await getFullQuote(values.id, quote.quote)
            let service = await getService(values.id, q.service)
            setQuote(quote);
            setQ(q);
            setQuestions(mergeQuestionsAndResponses(service.requirements, q.responses))
        }

        fetchQuote();
    }, []);

    function mergeQuestionsAndResponses(questions, responses) {
        if (questions.length !== responses.length) {
            throw new Error("Questions and responses must have the same length");
        }

        return questions.map((question, index) => {
            return {
                question: question.question,
                response_type: question.response_type,
                response: responses[index].response,
            };
        });
    }

    async function getURL() {
        setLoading(true)
        let responseID = await uploadResponse(q.service, q.responses, "Quote");
        getCheckoutUrl(q.service, quote, responseID, true);
    }

    return (
        <>
            <Header />
            <div className="home-body content">
                {loading ?
                    <div className="max-w h-screen flex items-center justify-center">
                        <img className="h-12 inline-block" src={gif} />
                    </div>
                    :
                    <>
                        {quote && (
                            pdf ?
                                <div className="mt-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <button
                                        type="button"
                                        class="mb-4 w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-900 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto"
                                        onClick={() => setPDF(!pdf)}>
                                        <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                        </svg>
                                        <span>Go back</span>
                                    </button>
                                    <iframe
                                        title="Quote PDF"
                                        src={quote.url}
                                        className="w-full h-screen"
                                    />
                                </div>
                                :
                                <div className="mt-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 grid grid-cols-6 gap-4">
                                    <div className="p-4 col-span-2">
                                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                                            {q.service} (Quote)
                                        </h1>
                                        <p className="mt-3 mb-3 text-3xl tracking-tight text-gray-900">
                                            ${quote.price}
                                        </p>
                                        <p className="space-y-6 text-base text-gray-700">
                                            Quoted on {timeToStr(quote.time)}
                                        </p>
                                        {quote.url != null && <button className="mt-6 flex w-full items-center justify-center rounded-md bg-white px-8 py-1.5 text-base font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={() => setPDF(!pdf)}>View Detailed Quote</button>}
                                        <button
                                            className="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-1.5 text-base font-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => getURL()}>Purchase Now</button>
                                    </div>

                                    <div className="p-4 col-span-4">
                                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Your responses</h2>
                                        <p>
                                            {questions.map((item, index) => (
                                                <Disclosure as="div" key={item.question} className="pt-6">
                                                    {({ open }) => (
                                                        <>
                                                            <dt>
                                                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                                    <span className="text-base font-semibold leading-7">{item.question}</span>
                                                                    <span className="ml-6 flex h-7 items-center">
                                                                        {open ? (
                                                                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                                        ) : (
                                                                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                                        )}
                                                                    </span>
                                                                </Disclosure.Button>
                                                            </dt>
                                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                                <p className="text-base leading-7 text-gray-600">{item.response == "File" ? <Link to={item.response}>Link to File</Link> : item.response}</p>
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                        )}
                    </>
                }
            </div>
            <Footer />
        </>
    );
}

export default Quote