import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getService } from "../services/firebaseService";
import values from "../values";
import Nav2 from "../components/Nav2";
import { Tab } from '@headlessui/react'
import { ArrowPathIcon, CheckIcon, ClockIcon } from "@heroicons/react/24/outline";
import { incrementServiceSession } from "../services/dbService";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Service() {
    const { id } = useParams();
    const [service, setService] = useState(null);
    const [images, setImages] = useState([]);
    let navigate = useNavigate();
    const [currentTab, setTab] = useState(0);

    useEffect(() => {
        async function getElements() {
            let service = await getService(values.id, id);
            let images = service.gallery.images;
            setImages(images)
            setService(service);
            incrementServiceSession(values.id, id)
        }

        getElements();
    }, []);

    const movePages = (pkg, type) => {
        if (pkg.action == "Contact") {
            navigate("/contact")
        } else {
            navigate("/quiz/" + id + "/" + type)
        }
    }

    var breadcrumbs = [
        { id: 1, name: 'Offerings', href: '/' },
    ]

    async function changeTab(tab) {
        const tabs = ["good", "better", "best"]
        setTab(tabs.indexOf(tab))
    }

    return (
        <>
            <Header />
            <div className="home-body content">
                <div className="mt-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <Nav2 breadcrumbs={breadcrumbs} product={{ "name": id, "href": "#" }} />
                    {service != null ?
                        <>
                            <div className="mx-auto pt-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pt-10">
                                <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                                    <h1 className="mb-7 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{service.name}</h1>
                                    <div>
                                        <Tab.Group as="div" className="flex flex-col-reverse">
                                            {/* Image selector */}
                                            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                                                <Tab.List className="grid grid-cols-4 gap-6">
                                                    {images.map((image, index) => (
                                                        <Tab
                                                            key={index}
                                                            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                                                        >
                                                            {({ selected }) => (
                                                                <>
                                                                    {/* <span className="sr-only">{image.name}</span> */}
                                                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                                                        <img src={image} alt="Product Image" className="h-full w-full object-scale-down object-center" />
                                                                    </span>
                                                                    <span
                                                                        className={classNames(
                                                                            selected ? 'ring-blue-500' : 'ring-transparent',
                                                                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                </>
                                                            )}
                                                        </Tab>
                                                    ))}
                                                </Tab.List>
                                            </div>

                                            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                                                {images.map((image, index) => (
                                                    <Tab.Panel key={index}>
                                                        <img
                                                            src={image}
                                                            alt="Product Image"
                                                            className="h-full w-full max-h-80 object-scale-down object-center sm:rounded-lg"
                                                        />
                                                    </Tab.Panel>
                                                ))}
                                            </Tab.Panels>
                                        </Tab.Group>
                                    </div>
                                    <br />
                                    <div className="w-full mt-10 pb-20">
                                        <pre className="text-base text-gray-900 whitespace-pre-wrap">{service.description}</pre>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <div>
                                        <div className="sm:hidden">
                                            <label htmlFor="tabs" className="sr-only">
                                                Select a tab
                                            </label>
                                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                            <select
                                                id="tabs"
                                                name="tabs"
                                                className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                                                defaultValue="good"
                                                onChange={(e) => changeTab(e.target.value)}
                                            >
                                                <option key="good" value="good">{service.pricing.good.name}</option>
                                                <option key="better" value="better">{service.pricing.better.name}</option>
                                                <option key="best" value="best">{service.pricing.best.name}</option>
                                            </select>
                                        </div>
                                        <div className="hidden sm:block">
                                            <div className="border-b border-gray-200">
                                                <nav className="-mb-px flex" aria-label="Tabs">
                                                    <a
                                                        key="good"
                                                        onClick={() => changeTab("good")}
                                                        className={classNames(
                                                            currentTab == 0
                                                                ? 'border-blue-500 text-blue-600'
                                                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                            'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer'
                                                        )}
                                                        aria-current={currentTab == 0 ? 'page' : undefined}
                                                    >
                                                        {service.pricing.good.name}
                                                    </a>
                                                    <a
                                                        key="better"
                                                        onClick={() => changeTab("better")}
                                                        className={classNames(
                                                            currentTab == 1
                                                                ? 'border-blue-500 text-blue-600'
                                                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                            'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer'
                                                        )}
                                                        aria-current={currentTab == 1 ? 'page' : undefined}
                                                    >
                                                        {service.pricing.better.name}
                                                    </a>
                                                    <a
                                                        key="best"
                                                        onClick={() => changeTab("best")}
                                                        className={classNames(
                                                            currentTab == 2
                                                                ? 'border-blue-500 text-blue-600'
                                                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                            'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer'
                                                        )}
                                                        aria-current={currentTab == 2 ? 'page' : undefined}
                                                    >
                                                        {service.pricing.best.name}
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    {currentTab == 0 && <div className="mt-7">
                                        {service.pricing.good.action == "Purchase" && <p className="text-3xl tracking-tight text-gray-900 mb-6">${service.pricing.good.price}</p>}
                                        <p className="text-base text-gray-900 mb-6">{service.pricing.good.details}</p>
                                        <p className="font-medium text-black-900 mb-3"><ClockIcon className="mr-3 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <span className="align-middle">{service.pricing.good.delivery} days delivery</span></p>
                                        <p className="font-medium text-black-900 mb-6"><ArrowPathIcon className="mr-4 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /><span className="align-middle">{service.pricing.good.revisions} revisions</span></p>
                                        <p className="text-sm font-medium text-gray-500 mb-3">Included Items</p>
                                        {service.pricing.good.items_included.split(/\r?\n/).map(function (item, i) {
                                            return <p className="grid grid-cols-7 mb-2"><CheckIcon className="mr-2 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <div className="col-span-6">{item}</div></p>;
                                        })}
                                        {service.pricing.good.items_not_included != "" ? service.pricing.good.items_not_included.split(/\r?\n/).map(function (item, i) {
                                            return <p className="grid grid-cols-7 mb-2 opacity-30"><CheckIcon className="mr-2 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <div className="col-span-6">{item}</div></p>;;
                                        }) : <></>}
                                        <button
                                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => { movePages(service.pricing.good, "good") }}>
                                                {service.pricing.good.action == "Purchase" ? "Continue" : (service.pricing.good.action == "Quote" ? "Request a Quote" : "Contact")}
                                        </button>
                                    </div>
                                    }
                                    {currentTab == 1 && <div className="mt-7">
                                        {service.pricing.better.action == "Purchase" && <p className="text-3xl tracking-tight text-gray-900 mb-6">${service.pricing.better.price}</p>}
                                        <p className="text-base text-gray-900 mb-6">{service.pricing.better.details}</p>
                                        <p className="font-medium text-black-900 mb-3"><ClockIcon className="mr-3 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <span className="align-middle">{service.pricing.better.delivery} days delivery</span></p>
                                        <p className="font-medium text-black-900 mb-6"><ArrowPathIcon className="mr-4 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /><span className="align-middle">{service.pricing.better.revisions} revisions</span></p>
                                        <p className="text-sm font-medium text-gray-500 mb-3">Included Items</p>
                                        {service.pricing.better.items_included.split(/\r?\n/).map(function (item, i) {
                                            return <p className="grid grid-cols-7 mb-2"><CheckIcon className="mr-2 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <div className="col-span-6">{item}</div></p>;
                                        })}
                                        {service.pricing.better.items_not_included != "" ? service.pricing.better.items_not_included.split(/\r?\n/).map(function (item, i) {
                                            return <p className="grid grid-cols-7 mb-2 opacity-50"><CheckIcon className="mr-2 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <div className="col-span-6">{item}</div></p>;;
                                        }) : <></>}
                                        <button
                                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => { movePages(service.pricing.better, "better") }}>
                                                {service.pricing.better.action == "Purchase" ? "Continue" : (service.pricing.better.action == "Quote" ? "Request a Quote" : "Contact")}
                                        </button>
                                    </div>
                                    }
                                    {currentTab == 2 && <div className="mt-7">
                                        {service.pricing.best.action == "Purchase" && <p className="text-3xl tracking-tight text-gray-900 mb-6">${service.pricing.best.price}</p>}
                                        <p className="text-base text-gray-900 mb-6">{service.pricing.best.details}</p>
                                        <p className="font-medium text-black-900 mb-3"><ClockIcon className="mr-3 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <span className="align-middle">{service.pricing.best.delivery} days delivery</span></p>
                                        <p className="font-medium text-black-900 mb-6"><ArrowPathIcon className="mr-4 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /><span className="align-middle">{service.pricing.best.revisions} revisions</span></p>
                                        <p className="text-sm font-medium text-gray-500 mb-3">Included Items</p>
                                        {service.pricing.best.items_included.split(/\r?\n/).map(function (item, i) {
                                            return <p className="grid grid-cols-7 mb-2"><CheckIcon className="mr-2 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <div className="col-span-6">{item}</div></p>;
                                        })}
                                        {service.pricing.best.items_not_included != "" ? service.pricing.best.items_not_included.split(/\r?\n/).map(function (item, i) {
                                            return <p className="grid grid-cols-7 mb-2 opacity-50"><CheckIcon className="mr-2 h-5 w-5 text-gray-900 inline-block align-middle" aria-hidden="true" /> <div className="col-span-6">{item}</div></p>;;
                                        }) : <></>}
                                        <button
                                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => { movePages(service.pricing.best, "best") }}>
                                                {service.pricing.best.action == "Purchase" ? "Continue" : (service.pricing.best.action == "Quote" ? "Request a Quote" : "Contact")}
                                        </button>
                                    </div>
                                    }
                                </div>
                                {/*
                                    <Col className="pricing-content">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="good">
                                                <h4>${service.pricing.good.price}</h4>
                                                <p>{service.pricing.good.details}</p>
                                                <p style={{ fontWeight: "700" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg> {service.pricing.good.delivery} days delivery</p>
                                                <p style={{ fontWeight: "700" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                                                </svg> {service.pricing.good.revisions} revisions</p>
                                                {service.pricing.good.items_included.split(/\r?\n/).map(function (item, i) {
                                                    return <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                    </svg> {item}</p>;
                                                })}
                                                {service.pricing.good.items_not_included.split(/\r?\n/).map(function (item, i) {
                                                    return <p style={{ opacity: 0.5 }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                    </svg> {item}</p>;
                                                })}
                                                <Button style={{ backgroundColor: values.colors, borderColor: values.color }} onClick={() => { movePages(service.pricing.good, "good") }}>{service.pricing.good.action == "Purchase" ? "Continue" : "Contact"}</Button>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="better">
                                                <h4>${service.pricing.better.price}</h4>
                                                <p>{service.pricing.better.details}</p>
                                                <p style={{ fontWeight: "700" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg> {service.pricing.better.delivery} days delivery</p>
                                                <p style={{ fontWeight: "700" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                                                </svg> {service.pricing.better.revisions} revisions</p>
                                                {service.pricing.better.items_included.split(/\r?\n/).map(function (item, i) {
                                                    return <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                    </svg> {item}</p>;
                                                })}
                                                {service.pricing.better.items_not_included.split(/\r?\n/).map(function (item, i) {
                                                    return <p style={{ opacity: 0.5 }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                    </svg> {item}</p>;
                                                })}
                                                <Button style={{ backgroundColor: values.colors, borderColor: values.color }} onClick={() => { movePages(service.pricing.better, "better") }}>{service.pricing.better.action == "Purchase" ? "Continue" : "Contact"}</Button>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="best">
                                                <h4>${service.pricing.best.price}</h4>
                                                <p>{service.pricing.best.details}</p>
                                                <p style={{ fontWeight: "700" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg> {service.pricing.best.delivery} days delivery</p>
                                                <p style={{ fontWeight: "700" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                                                </svg> {service.pricing.best.revisions} revisions</p>
                                                {service.pricing.best.items_included.split(/\r?\n/).map(function (item, i) {
                                                    return <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                    </svg> {item}</p>;
                                                })}
                                                {service.pricing.best.items_not_included != "" ? service.pricing.best.items_not_included.split(/\r?\n/).map(function (item, i) {
                                                    return <p style={{ opacity: 0.5 }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                    </svg> {item}</p>;
                                                }) : <></>}
                                                <Button style={{ backgroundColor: values.colors, borderColor: values.color }} onClick={() => { movePages(service.pricing.best, "best") }}>{service.pricing.best.action == "Purchase" ? "Continue" : "Contact"}</Button>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Tab.Container> */}
                            </div>
                        </> : <div></div>}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Service