import React, { useState, useEffect } from "react";
import values from "../values";
import { useNavigate, useParams } from "react-router-dom";
import { createQuote, getService } from "../services/firebaseService";
import { imageUpload } from "../firebaseConfig";
import { getCheckoutUrl, uploadResponse } from "../services/stripeService";
import gif from "../loading.gif"

function Quiz() {
    const { id, pkg } = useParams();
    const [percent, setPercent] = useState(0);
    const [question, setQuestion] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState([]);
    const [textValue, setTextValue] = useState("");
    const [fileValue, setFileValue] = useState(null);
    const [service, setService] = useState(null);
    const [thePkg, setPKG] = useState(null);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        async function getElements() {
            let service = await getService(values.id, id);
            const pack = pkg == "good" ? service.pricing.good : pkg == "better" ? service.pricing.better : service.pricing.best;
            if(pack.action == "Quote") {
                setQuestions(service.requirements.concat([{"question": "What is your name?", "response_type": "input", "required": "yes"}, {"question": "What is your email?", "response_type": "input", "required": "yes"}]))
            } else {
                setQuestions(service.requirements)
            }
            setService(service)
            setPKG(pack)
        }

        getElements();
    }, []);

    const nextQuestion = async () => {
        if (questions[question].required == "Yes") {
            if ((questions[question].response_type == "Free Response" && textValue == "") || (questions[question].response_type == "input" && textValue == "")) {
                document.getElementById("invalid-input").style.display = "block"
                return;
            } else if (questions[question].response_type == "File" && fileValue == null) {
                document.getElementById("invalid-input").style.display = "block"
                return;
            }
        }
        if (question + 1 < questions.length) {
            if (responses.length <= question) {
                if (questions[question].response_type == "Free Response" || questions[question].response_type == "input") {
                    setResponses([...responses, textValue])
                    setTextValue("")
                } else {
                    setResponses([...responses, fileValue])
                    setFileValue(null)
                }
            } else {
                let newArr = [...responses]
                if (questions[question].response_type == "Free Response" || questions[question].response_type == "input") {
                    newArr[question] = textValue;
                } else {
                    newArr[question] = fileValue;
                }
                if (responses.length > question + 1) {
                    if (questions[question + 1].response_type == "Free Response" || questions[question + 1].response_type == "input") {
                        setTextValue(responses[question + 1])
                    } else {
                        setFileValue(responses[question + 1])
                    }
                } else {
                    setTextValue("")
                    setFileValue(null)
                }
                setResponses(newArr)
            }
            setPercent(Math.round((question + 1) / questions.length * 100))
            setQuestion(question + 1);
            document.getElementById("invalid-input").style.display = "none"
        } else {
            let final_response = []
            for (let i = 0; i < questions.length - 1; i++) {
                let response = {}
                response.response_type = questions[i].response_type;
                if (questions[question].response_type == "Free Response" || questions[question].response_type == "input") {
                    response.response = responses[i]
                } else {
                    response.response = imageUpload(responses[i], "responses")
                }
                final_response.push(response)
            }

            // submit
            let response = {}
            response.response_type = questions[questions.length - 1].response_type
            if (questions[question].response_type == "Free Response" || questions[question].response_type == "input") {
                setResponses([...responses, textValue])
                response.response = textValue;
                setTextValue("")
            } else {
                setResponses([...responses, fileValue])
                response.response = imageUpload(fileValue, "responses")
                setFileValue(null)
            }
            final_response.push(response)

            if(thePkg.action == "Purchase") {
                // checkout
                setLoading(true)
                let responseID = await uploadResponse(id, final_response, thePkg.name);
                const pack = pkg == "good" ? service.pricing.good : pkg == "better" ? service.pricing.better : service.pricing.best
                getCheckoutUrl(service.name, pack, responseID);
            } else {
                // quote
                setLoading(true)
                const name = final_response.at(-2).response
                const email = final_response.at(-1).response
                final_response.splice(-2)
                await createQuote(values.id, id, thePkg.name, name, email, final_response) //TODO - check if it is thePkg.name or pkg
                navigate("/success-quote")
            }

        }
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setTextValue(value);
    }

    const handleFile = (e) => {
        const files = e.target.files;
        setFileValue(files[0]);
    }

    const prevQuestion = () => {
        if (question - 1 >= 0) {
            if (questions[question - 1].response_type == "Free Response"  || questions[question - 1].response_type == "input") {
                setTextValue(responses[question - 1])
            } else {
                setFileValue(responses[question - 1])
            }
            setPercent(Math.round((question - 1) / questions.length * 100))
            setQuestion(question - 1);
            document.getElementById("invalid-input").style.display = "none"
        } else {
            navigate("/service/" + id);
        }
    }

    return (
        <>
            <header className="quiz-header">
                <div class="centered-header">
                    <button className="quiz-back" onClick={() => { prevQuestion() }}>
                        <svg className="bi bi-chevron-left inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </button>
                    <div className="logo">
                        <img
                            src={values.icon}
                            width="50"
                            height="50"
                            className="inline-block align-middle"
                            alt="Logo"
                        />
                    </div>
                    <div className="percentageDone">
                        <div className="percent" style={{ background: values.colors }}>
                            <span>{percent}</span>
                        </div>
                    </div>
                </div>
                <div className="headerProgress" style={{ width: percent + "%", background: values.colors }}></div>
            </header>
            <div className="space-y-12">
                <div className="mx-auto pb-12 max-w-lg">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{id} Questions</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        Help us understand what your needs are.
                    </p>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-">
                        {loading ? <div className="max-w-sm">
                            <img className="h-12" src={gif} />
                        </div> :
                            <>
                                <div className="sm:col-span-4">
                                    <h3 className="block text-sm font-medium leading-6 text-gray-900">{questions[question] != null ? questions[question].question : ""}</h3>
                                </div>
                                {/* <div className="cool-stuff"></div> */}
                                <div className="response">
                                    {questions[question] != null ? questions[question].response_type == "Free Response" ?
                                        <textarea className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" id="text-response" value={textValue} onChange={handleChange} /> : (questions[question].response_type == "input" ? <input type="text" className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" value={textValue} onChange={handleChange} /> : <input type="file" id="file-response" onChange={handleFile} />) : ""}
                                    <div className="d-grid gap-2">
                                        <button className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-1.5 text-base font-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" type="button" style={{ background: values.colors }} variant="primary" size="lg" onClick={() => { nextQuestion() }}>{question + 1 == questions.length ? (thePkg.action == "Purchase" ? "Checkout" : "Get Quoted") : "Next"}</button>
                                    </div>
                                    <div id="invalid-input" className="invalid-input mt-6">
                                        This field is required.
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Quiz