import React, { useEffect, useState } from "react";
import values from "../values";
import { getSite } from "../services/firebaseService";
import { Link } from "react-router-dom";

function Footer() {

    const navigation = {
        main: [
            { name: 'Services', href: '/' },
            { name: 'Gallery', href: '/gallery' },
            { name: 'Contact Us', href: '/contact' },
            // { name: 'Terms & Conditions', href: '/terms' },
            // { name: 'Privacy', href: '/privacy' }
        ],
        social: [
            {
                name: 'LinkedIn',
                href: '#',
                icon: (props) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"
                            clipRule="evenodd"
                        />
                        <rect x="2" y="9" width="4" height="12" />
                        <circle cx="4" cy="4" r="2" />
                    </svg>
                ),
            },
            {
                name: 'Instagram',
                href: '#',
                icon: (props) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
            {
                name: 'Twitter',
                href: '#',
                icon: (props) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                ),
            },
        ],
    }

    const [site, setSite] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const site = await getSite(values.id);
                setSite(site);
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        fetchData();
    }, []);

    return (
        // <Navbar expand="lg" className="m-auto" bg="light" data-bs-theme="light">
        //     <Container>
        //         <span style={{flex: 1}}>@2023 {values.name}</span> {' '}
        //         <Navbar.Brand href="../" style={{flex: 1, textAlign: "center"}}>
        //             <img
        //             src={values.icon}
        //             width="30"
        //             height="30"
        //             className="d-inline-block align-top"
        //             alt="Logo"
        //             />
        //         </Navbar.Brand>
        //         <Nav className="ml-auto" style={{flex: 1}}>
        //             <Nav.Link as={Link} to="/">Home</Nav.Link>
        //             <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
        //             <Nav.Link as={Link} to="/gallery">Gallery</Nav.Link>
        //             <Nav.Link as={Link} to="/terms">Terms & Conditions</Nav.Link>
        //             <Nav.Link as={Link} to="/privacy">Privacy</Nav.Link>
        //         </Nav>
        //     </Container>
        // </Navbar>
        <footer className="bg-white mx-auto">
            <div className="overflow-hidden px-6 py-20 sm:py-24 border-t border-gray-900/10">
                <div className="mb-10 flex justify-center space-x-10">
                    <img
                        className="h-12 w-auto"
                        src={values.icon}
                        alt=""
                    />
                </div>
                <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="pb-6">
                            <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                                {item.name}
                            </Link>
                        </div>
                    ))}
                </nav>
                {site != null ?
                    <div className="mt-10 flex justify-center space-x-10">
                        {navigation.social.map((item) => {
                            var rt = false;
                            var link = "#"
                            if (site.insta != null && item.name == "Instagram") {
                                rt = true;
                                link = site.insta;
                            } else if (site.twitter != null && item.name == "Twitter") {
                                rt = true;
                                link = site.twitter;
                            } else if (site.linkedin != null && item.name == "LinkedIn") {
                                rt = true;
                                link = site.linkedin;
                            }

                            if (rt) {
                                return (
                                    <Link key={item.name} to={link} className="text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">{item.name}</span>
                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                    </Link>
                                )
                            } else {
                                return (
                                    <></>
                                )
                            }
                        })}
                    </div>
                    : <></>}
                <p className="mt-10 text-center text-xs leading-5 text-gray-500">
                    &copy; 2024 {values.name}. All rights reserved.
                </p>
            </div>
        </footer>
    );
}

export default Footer