import React from "react";
import "../styles/Success.css"
import { Link } from "react-router-dom";

const products = [
    {
        id: 1,
        name: 'Cold Brew Bottle',
        description:
            'This glass bottle comes with a mesh insert for steeping tea or cold-brewing coffee. Pour from any angle and remove the top for easy cleaning.',
        href: '#',
        quantity: 1,
        price: '$32.00',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/confirmation-page-05-product-01.jpg',
        imageAlt: 'Glass bottle with black plastic pour top and mesh insert.',
    },
]

function Success(props) {

    return (
        <main className="bg-white px-4 pb-24 pt-16 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
            <div className="mx-auto max-w-3xl">
                <div className="max-w-xl">
                    <h1 className="text-base font-medium text-blue-600">Thank you!</h1>
                    <p className="mt-2 text-4xl font-bold tracking-tight">It's on the way!</p>
                    <p className="mt-2 text-base text-gray-500">Your order is being processed. We will send an email with the details and confirmation of your order.</p>
                </div>
                <div className="max-w-md mt-1">
                    <Link to={"/"}><button className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Return to site</button></Link>
                </div>
            </div>
        </main>
    )
}

export default Success