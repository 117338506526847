import { db } from "../firebaseConfig";
import {  Timestamp, addDoc, collection, doc, increment, updateDoc } from "firebase/firestore";

export class ContactUs {
    constructor(site, name, email, message, company, phone, country) {
        this.site = site;
        this.name = name;
        this.email = email;
        this.message = message;
        this.company = company;
        this.phone = phone;
        this.country = country;
    }

    from_json(data) {
        return new ContactUs(data.site, data.name, data.email, data.message, data.company, data.phone, data.country);
    }

    to_json() {
        return {
            site: this.site,
            name: this.name,
            email: this.email,
            message: this.message,
        }
    }

    async submit() {
        await updateDoc(doc(db, `sites/${this.site}/contacts/${this.email}`), {
            name: this.name,
            email: this.email,
            company: this.company != undefined ? this.company : "",
            phone: this.phone,
            country: this.country,
            lastMessage: Timestamp.now(),
            unread: increment(1)
        })

        await addDoc(collection(db, `sites/${this.site}/contacts/${this.email}/messages`), {
            message: this.message,
            time: Timestamp.now(),
            status: 'Unread'
        })
    }
}