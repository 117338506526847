import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import values from "../values";
import { getDelivery } from "../services/firebaseService";
import { DocumentArrowDownIcon, TruckIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

function Delivery() {
    const {id} = useParams();

    const [delivery, setDelivery] = useState(null)

    useEffect(() => {
        async function fetchDelivery() {
            let delivery = await getDelivery(values.id, id);
            setDelivery(delivery);
        }

        fetchDelivery();
    }, []);

    async function download_file(name, url) {
        let blob = await fetch(url).then(r => r.blob());

        var dlink = document.createElement('a');
        dlink.download = name;
        dlink.href = window.URL.createObjectURL(blob);
        dlink.onclick = function(e) {
            // revokeObjectURL needs a delay to work properly
            var that = this;
            setTimeout(function() {
                window.URL.revokeObjectURL(that.href);
            }, 1500);
        };

        dlink.click();
        dlink.remove();
    }

    return (
        <>
            <Header />
            <main className="bg-white px-4 pb-24 pt-16 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
                <div className="mx-auto max-w-3xl">
                    <div className="max-w-xl mb-10">
                        <h1 className="text-base font-medium text-blue-600">Thank you!</h1>
                        <p className="mt-2 text-4xl font-bold tracking-tight">Your order has arrived!</p>
                        <p className="mt-2 text-base text-gray-500">Click the box below to download your delivery.</p>
                    </div>
                    {delivery != null && delivery.file != null ?
                        <button
                            type="button"
                            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mb-5"
                            onClick={() => download_file(values.id + "-delivery", delivery.file.url)}
                        >
                            <DocumentArrowDownIcon className="mx-auto h-12 w-12 text-gray-400"  />
                            <span className="mt-2 block text-sm font-semibold text-gray-900">Download File</span>
                        </button> :
                        <button
                            type="button"
                            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none mb-5"
                        >
                            <TruckIcon className="mx-auto h-12 w-12 text-gray-400" />
                            <span className="mt-2 block text-sm font-semibold text-gray-900">Your order has been delivered.</span>
                        </button>
                    }
                    {
                        delivery != null && delivery.time != null &&
                        <p>Delivered on {delivery.time.toDate().toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</p>
                    }
                </div>
            </main>
            <Footer />
        </>
    );
}

export default Delivery