export class Site {
    constructor(name, url, icon, colors, slogan, sales, orders, sessions, accountID, insta, twitter, linkedin, customerID) {
        this.name = name;
        this.url = url;
        this.icon = icon;
        this.colors = colors;
        this.slogan = slogan;
        this.sales = sales;
        this.orders = orders;
        this.sessions = sessions;
        this.accountID = accountID;
        this.insta = insta;
        this.twitter = twitter;
        this.linkedin = linkedin;
        this.customerID = customerID;

        this[name] = name;
        this[url] = url;
        this[icon] = icon;
        this[colors] = colors;
        this[slogan] = slogan;
        this[sales] = sales;
        this[orders] = orders;
        this[sessions] = sessions;
        this[accountID] = accountID;
        this[insta] = insta;
        this[twitter] = twitter;
        this[linkedin] = linkedin;
        this[customerID] = customerID
    }
}