import { db } from "../firebaseConfig";
import { Gallery, Package, Pricing, Question, Service } from "../classes/serviceClass";
import { collection, query, where, getDoc, getDocs, orderBy, startAfter, doc, limit, addDoc, Timestamp, documentId } from "firebase/firestore";
import { Site } from "../classes/siteClass";
import values from "../values";

export async function getSite(name) {
    const docSnap = await getDoc(doc(db, 'sites', name));
    if (docSnap.exists()) {
        let data = docSnap.data();
        return new Site(data.name, docSnap.id, data.icon, data.colors, data.slogan, data.sales, data.orders, data.sessions, data.accountID, data.insta, data.twitter, data.linkedin, data.customerID);
    } else {
        // TODO - fail
        return;
    }
}

export async function getServices(site, lastDoc) {
    let q = null;
    if (lastDoc == null) {
        q = query(collection(db, "sites", site, "services"), orderBy("name"), limit(15));
    } else {
        q = query(collection(db, "sites", site, "services"), orderBy("name"), startAfter(lastDoc), limit(15));
    }
    const querySnapshot = await getDocs(q);
    let services = [];
    querySnapshot.forEach((doc) => {
        let data = doc.data();
        const requirements = []
        for (let question in data.questions) {
            requirements.push(new Question().from_json(question))
        }
        services.push(new Service(site, data.name, new Pricing(new Package().from_json(data.good), new Package().from_json(data.better), new Package().from_json(data.best)), data.description, requirements, new Gallery(data.images, data.yt_videos)))
    });
    return services;
}

export async function getService(site, serviceId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/services/${serviceId}`));
    if (docSnap.exists()) {
        let data = docSnap.data();
        const requirements = []
        for (var i = 0; i < data.questions.length; i++) {
            requirements.push(new Question().from_json(data.questions[i]))
        }
        return new Service(data.site, data.name, new Pricing(new Package().from_json(data.good), new Package().from_json(data.better), new Package().from_json(data.best)), data.description, requirements, new Gallery(data.images, data.yt_videos));
    } else {
        // TODO - fail
        return;
    }
}

export async function searchOfferings(searchTerm) {
    if (searchTerm != '') {
        const q = query(collection(db, `sites/${values.id}/services/`), where(documentId(), '>=', searchTerm), where(documentId(), '<=', searchTerm + '\uf8ff'), limit(10));
        const querySnapshot = await getDocs(q);
        let offerings = [];
        querySnapshot.forEach((doc) => {
            offerings.push(doc.id)
        });
        return offerings;
    } else {
        return []
    }
}

export async function createQuote(site, service, pkg, name, email, responses) {
    await addDoc(collection(db, `sites/${site}/quotes/`), {
        "email": email,
        "name": name,
        "package": pkg,
        "responses": responses,
        "service": service,
        "status": "Unquoted",
        "time": Timestamp.now()
    })
}

export async function getDelivery(site, deliveryId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/fulfillments/${deliveryId}`));
    if (docSnap.exists()) {
        let data = docSnap.data();
        return data;
    } else {
        // TODO - fail
        return;
    }
}

export async function getQuote(site, quoteId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/sent-quotes/${quoteId}`));
    if (docSnap.exists()) {
        let data = docSnap.data();
        return data;
    } else {
        // TODO - fail
        return;
    }
}

export async function getFullQuote(site, quoteId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/quotes/${quoteId}`));
    if (docSnap.exists()) {
        let data = docSnap.data();
        return data;
    } else {
        // TODO - fail
        return;
    }
}

export function timeToStr(time) {
    return time.toDate().toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" });
}

export async function getAllImages(site) {
    const querySnapshot = await getDocs(collection(db, `sites/${site}/services`));
    let images = [];
    querySnapshot.forEach((doc) => {
        let data = doc.data();
        let i = data.images;
        var imgs = []
        for(var image in i) {
            imgs.push({"service": data.name, "src": i[image]})
        }
        if(imgs && Array.isArray(imgs)) {
            images.push(...imgs)
        }
    });
    return images;
}