import { Link } from "react-router-dom";

export function HomeCard({service, index}) {
    return (
        <Link to={"/service/" + service.name} className="font-medium text-gray-900 hover:text-gray-600">
            <div className="relative cursor-pointer">
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  <img
                    src={service.gallery.images[0]}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="relative mt-4">
                  <h3 className="text-lg font-medium text-gray-900">{service.name}</h3>
                  <h3 className="text-sm font-normal text-gray-500 line-clamp-3">{service.description}</h3>
                </div>
                <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                  />
                  <p className="relative text-lg font-semibold text-white">{service.pricing.good.price != null ? "$" + service.pricing.good.price : "Request a Quote"}</p>
                </div>
            </div>
            </Link>
    )
}

